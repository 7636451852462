var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "patrolLogManagerList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          exportPermission: "patrolExport",
          searchUrl: _vm.searchUrl,
          exportUrl: _vm.exportUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: {
                    text: "初始化本月新设备巡检日志",
                    permission: "generatePatrol",
                  },
                  on: { click: _vm.handlePatrolLogInit },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-datepicker", {
                  attrs: {
                    label: "巡检日期时间段",
                    startTime: _vm.searchParams.startDate,
                    endTime: _vm.searchParams.endDate,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "startDate", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "startDate", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "endDate", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "endDate", $event)
                    },
                  },
                }),
                _c("v-input", {
                  attrs: { label: "设备名称" },
                  model: {
                    value: _vm.searchParams.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "name", $$v)
                    },
                    expression: "searchParams.name",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "设备编号" },
                  model: {
                    value: _vm.searchParams.serialNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "serialNum", $$v)
                    },
                    expression: "searchParams.serialNum",
                  },
                }),
                _c("v-select3", {
                  attrs: {
                    label: "所属项目",
                    communityParams: _vm.communityParams,
                  },
                  model: {
                    value: _vm.searchParams.communityIds,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "communityIds", $$v)
                    },
                    expression: "searchParams.communityIds",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "巡检状态", options: _vm.statusOps },
                  model: {
                    value: _vm.searchParams.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "status", $$v)
                    },
                    expression: "searchParams.status",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "执行方式", options: _vm.typeOps },
                  model: {
                    value: _vm.searchParams.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "type", $$v)
                    },
                    expression: "searchParams.type",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属公司" },
                      model: {
                        value: _vm.searchParams.regionId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "regionId", $$v)
                        },
                        expression: "searchParams.regionId",
                      },
                    },
                    "v-select2",
                    _vm.tenantParams,
                    false
                  )
                ),
                _c("v-input", {
                  attrs: { label: "所属组团" },
                  model: {
                    value: _vm.searchParams.area,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "area", $$v)
                    },
                    expression: "searchParams.area",
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    label: "巡检完成时间段",
                    startTime: _vm.searchParams.fromDate,
                    endTime: _vm.searchParams.toDate,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "fromDate", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "fromDate", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "toDate", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "toDate", $event)
                    },
                  },
                }),
                _c("v-input", {
                  attrs: { label: "负责人" },
                  model: {
                    value: _vm.searchParams.chargerUserName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "chargerUserName", $$v)
                    },
                    expression: "searchParams.chargerUserName",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "执行人" },
                  model: {
                    value: _vm.searchParams.executorName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "executorName", $$v)
                    },
                    expression: "searchParams.executorName",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: { text: "编辑", type: "text", permission: "update" },
                  on: {
                    click: function ($event) {
                      return _vm.edit(scope.row)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }