import { generateMapByOpts } from 'common/utils'

//  巡检状态
const statusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '待巡检',
    value: 1
  },
  {
    text: '已巡检',
    value: 2
  },
  {
    text: '超时未巡检',
    value: 3
  },
  {
    text: '超时已巡检',
    value: 6
  },
  {
    text: '作废',
    value: 4
  },
  {
    text: '漏检',
    value: 5
  }
]

// 执行方式
const typeOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '蓝牙',
    value: 1
  },
  {
    text: '二维码',
    value: 2
  },
  {
    text: '非蓝牙',
    value: 3
  }
]

// form表单页面的操作选项
const handleOps = [
  {
    text: '请选择',
    value: undefined
  },
  {
    text: '作废',
    value: 4
  }
]

// 订单状态
const orderStatusOps = [
  {
    text: '待接收',
    value: 1
  },
  {
    text: '处理中',
    value: 2
  },
  {
    text: '已处理',
    value: 3
  },
  {
    text: '已完成',
    value: 4
  },
  {
    text: '已关闭',
    value: 5
  },
  {
    text: '待处理',
    value: 6
  }
]
// 新订单状态
const newOrderStatusOps = [
  {
    text: '待接收',
    value: 1
  },
  {
    text: '待处理',
    value: 2
  },
  {
    text: '超时未接收',
    value: 3
  },
  {
    text: '超时未处理',
    value: 4
  },
  {
    text: '处理中',
    value: 5
  },
  {
    text: '已处理',
    value: 6
  },
  {
    text: '已完成',
    value: 7
  },
  {
    text: '已关闭',
    value: 8
  },
  {
    text: '超时处理中',
    value: 9
  },
]
const statusMap = generateMapByOpts(statusOps)
const typeMap = generateMapByOpts(typeOps)
const orderStatusMap = generateMapByOpts(orderStatusOps)
const newOrderStatusMap = generateMapByOpts(newOrderStatusOps)

export {
  statusOps,
  statusMap,
  typeOps,
  typeMap,
  handleOps,
  orderStatusMap,
  newOrderStatusMap
}
