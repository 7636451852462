<!--
 * @Description: 巡检管理--列表页面
 * @Author: 小广
 * @Date: 2019-05-29 18:33:57
 * @LastEditors: 小广
 * @LastEditTime: 2019-08-23 11:40:45
 -->
<template>
  <div class="patrolLogManagerList-wrapper">
    <list
      ref="list"
      exportPermission="patrolExport"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button
          text="初始化本月新设备巡检日志"
          permission="generatePatrol"
          @click="handlePatrolLogInit"
        ></v-button>
      </template>
      <template #searchSlot>
        <v-datepicker
          label="巡检日期时间段"
          :startTime.sync="searchParams.startDate"
          :endTime.sync="searchParams.endDate"
        ></v-datepicker>
        <v-input label="设备名称" v-model="searchParams.name"></v-input>
        <v-input label="设备编号" v-model="searchParams.serialNum"></v-input>
        <!-- <v-select2
          label="所属项目"
          v-model="searchParams.communityIds"
          clearable
          v-bind="communityParams"
        ></v-select2> -->
        <v-select3
          v-model="searchParams.communityIds"
          label="所属项目"
          :communityParams="communityParams"
        >
        </v-select3>
        <v-select
          label="巡检状态"
          v-model="searchParams.status"
          :options="statusOps"
        ></v-select>
        <v-select
          label="执行方式"
          v-model="searchParams.type"
          :options="typeOps"
        ></v-select>
        <v-select2
          label="所属公司"
          v-model="searchParams.regionId"
          v-bind="tenantParams"
        ></v-select2>
        <v-input label="所属组团" v-model="searchParams.area"></v-input>
        <v-datepicker
          label="巡检完成时间段"
          :startTime.sync="searchParams.fromDate"
          :endTime.sync="searchParams.toDate"
        ></v-datepicker>
        <v-input label="负责人" v-model="searchParams.chargerUserName"></v-input>
        <v-input label="执行人" v-model="searchParams.executorName"></v-input>
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          type="text"
          permission="update"
          @click="edit(scope.row)"
        ></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import {
  getListURL,
  exportListURL,
  handlePatrolLogInitURL
} from './api'
import { statusOps, typeOps } from './map'
import moment from 'moment'
import { createHTMLVNode } from 'common/vdom'
import { communityParams, tenantParams } from 'common/select2Params'
import qs from 'qs'

export default {
  name: '',

  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      statusOps: statusOps,
      typeOps: typeOps,
      communityParams,
      tenantParams,
      searchParams: {
        name: '',
        serialNum: '',
        status: undefined,
        type: undefined,
        communityIds: '',
        regionId: '',
        area: '',
        startDate: this.calculateDate(false, 1),
        endDate: this.calculateDate(true, 0),
        fromDate: '',
        toDate: '',
        chargerUserName: '',
        executorName: ''
      },
      headers: [
        {
          prop: 'name',
          label: '设备名称'
        },
        {
          prop: 'serialNum',
          label: '设备编号'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'area',
          label: '所属组团'
        },
        {
          prop: 'patrolType',
          label: '执行方式'
        },
        {
          prop: 'statusName',
          label: '巡检状态'
        },
        {
          prop: 'exceptions',
          label: '异常个数',
          align: 'right'
        },
        {
          prop: 'patrolDate',
          label: '巡检日期'
        },
        {
          prop: 'uploadTime',
          label: '上传时间'
        },
        {
          prop: 'finishDate',
          label: '巡检完成时间'
        },
        {
          prop: 'chargerUserName',
          label: '负责人',
          formatter: (row, prop) => {
            let name = row.chargerUserName ? row.chargerUserName : ''
            let mobile = row.chargerUserPhone ? row.chargerUserPhone : ''
            return createHTMLVNode(this, name ? `${name}<br/>${mobile}` : '')
          }
        },
        {
          prop: 'executorName',
          label: '执行人',
          formatter: (row, prop) => {
            let name = row.executorName ? row.executorName : ''
            let mobile = row.executorMobile ? row.executorMobile : ''
            return createHTMLVNode(this, name ? `${name}<br/>${mobile}` : '')
          }
        }
      ]
    }
  },
  methods: {
    // 初始化本月新设备巡检日志的操作
    handlePatrolLogInit () {
      // 初始化日志
      let _this = this
      this.$confirm(
        '该操作将生成大量巡检日志数据，建议在晚上8点~次日7点间进行操作，是否确定继续操作？'
      ).then(confirm => {
        if (confirm) {
          _this.requestForPatrolLogInit()
        }
      })
    },

    // 计算时间,type类型，days天数
    calculateDate (add, days) {
      // true加 false减
      if (add) {
        return moment().add(days, 'd').format('YYYY-MM-DD')
      } else {
        return moment().subtract(days, 'months').format('YYYY-MM-DD')
      }
    },

    edit (row) {
      this.$router.push({
        name: 'patrolLogManagerForm',
        query: {
          id: row.id
        }
      })
    },

    // 初始化本月新设备巡检日志的请求
    requestForPatrolLogInit () {
      let loading = this.$loading({
        lock: true,
        text: '正在处理...，请不要离开该页面（^_^）',
        spinner: 'el-icon-loading'
      })
      let _this = this
      this.$axios
        .get(handlePatrolLogInitURL)
        .then(res => {
          loading.close()
          if (res.status === '100') {
            _this.$refs.list.searchData()
            _this.$message.success('执行成功')
          }
        })
        .catch(res => {
          loading.close()
        })
    },
  }
}
</script>
